@import '../../../../../../node_modules/@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$crediteo-primary: (
		50:  #395470,
		100: #324b63,
		200: #293a4e,
		300: #213141,
		400: #1c2836,
		500: #16202b,
		600: #141e29,
		700: #131b27,
		800: #121c25,
		900: #101820,
		A100: #395470,
		A200: #27394d,
		A400: #16202b,
		A700: #101820,
		contrast: (
				50: $dark-primary-text,
				100: $dark-primary-text,
				200: $dark-primary-text,
				300: $dark-primary-text,
				400: $dark-primary-text,
				500: $light-primary-text,
				600: $light-primary-text,
				700: $light-primary-text,
				800: $light-primary-text,
				900: $light-primary-text,
				A100: $dark-primary-text,
				A200: $dark-primary-text,
				A400: $dark-primary-text,
				A700: $dark-primary-text,
		)
);

$crediteo-accent: (
		50:  #b0de33,
		100: #aadc24,
		200: #9fce21,
		300: #aadc24,
		400: #9fce21,
		500: #94bf1f,
		600: #89b01d,
		700: #7da21a,
		800: #729318,
		900: #678516,
		A100: #a8d923,
		A200: #9fce21,
		A400: #94bf1f,
		A700: #8bb41d,
		contrast: (
				50: $dark-primary-text,
				100: $dark-primary-text,
				200: $dark-primary-text,
				300: $dark-primary-text,
				400: $dark-primary-text,
				500: $light-primary-text,
				600: $light-primary-text,
				700: $light-primary-text,
				800: $light-primary-text,
				900: $light-primary-text,
				A100: $dark-primary-text,
				A200: $dark-primary-text,
				A400: $dark-primary-text,
				A700: $dark-primary-text,
		)
);


$crediteo-app-primary: mat-palette($crediteo-primary);
$crediteo-app-accent:  mat-palette($crediteo-accent);

// The warn palette is optional (defaults to red).
$crediteo-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$crediteo-app-theme: mat-light-theme($crediteo-app-primary, $crediteo-app-accent, $crediteo-app-warn);

.cre-mat-background-primary {
	background-color: mat-color($crediteo-app-primary, 500) !important;
	color: $light-primary-text !important;
}

.cre-mat-border-color-accent {
	border-color: mat-color($crediteo-app-accent, 500) !important;
}

///* SCROLLABLE DIRECTIVE REQUIREMENTS */
:not(table)[scrollable], table[scrollable]>tbody {
	overflow-y: auto;
	display: block;
	height: calc(100vh - 316px);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($crediteo-app-theme);
